<template>
  <div class="textarea" :class="className" @click="$refs.textarea.focus()">
    <label for="input" class="textarea__label">
      {{ header }}
    </label>
    <div class="textarea__body">
      <textarea
        name="textarea"
        id="input"
        v-model="comment"
        :placeholder="placeholder"
        ref="textarea"
      ></textarea>
      <div class="textarea__counter">{{ length }}/{{ maxValue }}</div>
    </div>

    <div class="form__error" v-if="errorArray && errorArray[0]">
      {{ errorArray[0].$message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TextAreaBlock",
  components: {},

  props: {
    modelValue: [String, Number],
    placeholder: { type: [String, Number], default: "Комментарий..." },
    maxValue: { type: Number, default: 2000 },
    errorArray: Array,
    error: Boolean,
    header: [String, Number],
    disabled: { type: Boolean, default: false },
  },
  computed: {
    maxLength() {
      return this.length > this.maxValue;
    },
    length() {
      return this.comment?.length;
    },
    comment: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    className() {
      return {
        "textarea--error": this.error,
        "textarea--maxlength": this.maxLength,
        "textarea--disabled": this.disabled,
      };
    },
  },
};
</script>

<style lang="scss">
.textarea {
  display: flex;
  flex-direction: column;
  gap: 6px;

  textarea {
    min-width: 100%;
    min-height: 100px;
    border: none;
    resize: vertical;
    overflow: auto;
    font-size: 1.6rem;
    line-height: 1.9rem;
    @include scrollbar();
  }
  &__label {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  &__body {
    padding: 10px 8px;
    border: 1px solid var(--input--border);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    &:hover,
    &:focus {
      border-color: var(--background--main);
    }
  }

  &__counter {
    color: var(--input--placeholder);
    font-size: 1.6rem;
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 0.35rem;
  }
  &--disabled {
    pointer-events: none;

    .textarea__body {
      opacity: 0.5;
      background: var(--background--disabled);
      & textarea {
        background: var(--background--disabled);
      }
    }
    .textarea__counter {
      display: none;
    }
  }
  &--maxlength {
    .textarea__counter {
      &::before {
        content: "\e901";
        font-family: "icomoon";
        font-size: 0.8rem;
        color: #ef4444;
      }
    }
    .textarea__body {
      border: 1px solid var(--red);
    }
  }
  &--error {
    position: relative;
    margin-bottom: 15px;
    .form__error {
      color: var(--red);
      position: absolute;
      top: 100%;
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
    }
    .textarea__counter {
      &::before {
        content: "\e901";
        font-family: "icomoon";
        font-size: 0.8rem;
        color: #ef4444;
      }
    }
    .textarea__body {
      border: 1px solid var(--red);
    }
  }
}
</style>
