<template>
  <label class="radio">
    <input
      :id="uniqueId"
      :name="'radio' + uniqueId"
      type="radio"
      :disabled="disabled"
      :checked="item.value === modelValue"
      v-model="value"
      :value="item.value"
    />
    <label :for="uniqueId" class="radio__label">
      <span><slot></slot></span>
    </label>
  </label>
</template>

<script>
import uniqueString from "unique-string";

export default {
  name: "Radio",
  data() {
    return {
      uniqueId: uniqueString(),
    };
  },
  props: {
    item: [Object],
    modelValue: [Boolean, Number, String],
    disabled: { type: Boolean, default: false },
    checked: { type: Boolean, default: false },
    id: [String, Number],
    for: [String, Number],
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    className() {
      return {
        "toggle--disabled": this.disabled,
      };
    },
  },
};
</script>

<style lang="scss"></style>
