<template>
  <div class="tabs-radio" :class="className">
    <div class="tabs-radio__field">
      <Radio
        v-for="(item, index) in tabsRadioItems"
        :key="index"
        v-model="value"
        :value="item.value"
        :item="item"
      >
        {{ item.text }}
      </Radio>
      <Icon class="icon-Warning tabs-radio__error" />
    </div>
    <div class="tabs-radio__fix" v-if="isAlertShown">
      <p>
        Вам предложено исправление:
        <span>{{ newFieldValue === "male" ? "Мужской" : "Женский" }}</span>
      </p>
      <Button small @click="agree">Применить</Button>
    </div>
  </div>
</template>

<script>
import Radio from "@/components/Blocks/Radio";
import Icon from "@/components/Blocks/Icon";
import { mapGetters } from "vuex";
import Button from "@/components/Blocks/Button";

export default {
  components: { Button, Icon, Radio },
  props: {
    error: Boolean,
    modelValue: [Boolean, Number, String],
    disabled: Boolean,
    tabData: {
      type: Array,
    },
    editFieldName: String,
  },
  computed: {
    newFieldValue() {
      if (this.infoFix) {
        if (this.infoFix[this.editFieldName]) {
          return this.infoFix[this.editFieldName];
        }
      }
      return "";
    },
    isAlertShown() {
      return Boolean(!!this.newFieldValue && !this.isNewValueSimilar);
    },
    isNewValueSimilar() {
      return this.newFieldValue === this.value;
    },
    className() {
      return {
        "tabs-radio--error": this.error,
        "tabs-radio--disabled": this.disabled,
      };
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    tabsRadioItems() {
      return this.tabData.map((item, index) => {
        item.value = this.tabData[index].value;
        item.text = this.tabData[index].text;
        return item;
      });
    },
    ...mapGetters({ infoFix: "user/infoFix" }),
  },
  methods: {
    agree() {
      this.value = this.newFieldValue;
    },
  },
};
</script>

<style lang="scss"></style>
