<template>
  <div class="input" :class="className">
    <div class="input__field">
      <span class="input__label">
        {{ header }}
        <span class="input__label-info" v-if="infoText">
          <Icon type="Help" :data-tooltip="infoText" />
        </span>
      </span>
      <div class="input__item">
        <Icon class="input__icon" v-if="iconType" :type="iconType" />
        <input
          ref="input"
          :type="type"
          :name="type"
          :id="uniqueId"
          v-model.trim="value"
          :class="value ? 'is-focused' : ''"
          :readonly="readonly"
          :autocomplete="type"
          :placeholder="placeholder"
          :icon="icon"
          v-bind="$attrs"
          @blur="$emit('isBlur')"
          @keydown="$emit('isKeyDown', $event)"
          v-maska="mask"
        />
        <div class="input__icon--error"></div>
      </div>
      <label :for="uniqueId">{{ label }}</label>
    </div>
    <div class="input__fix" v-if="isAlertShown">
      <p>
        Вам предложено исправление: <span>{{ newFieldValue }}</span>
      </p>
      <Button small @click="agree">Применить</Button>
    </div>
  </div>
</template>

<script>
import uniqueString from "unique-string";
import Icon from "@/components/Blocks/Icon";
import { maska } from "maska";
import Button from "@/components/Blocks/Button";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "Input",
  directives: { maska },
  components: { Button, Icon },
  data() {
    return {
      uniqueId: uniqueString(),
    };
  },
  props: {
    label: String,
    modelValue: [String, Number],
    placeholder: [String, Number],
    type: String,
    error: Boolean,
    tooltipRight: Boolean,
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    mask: String,
    iconType: String,
    header: [String, Number],
    icon: String,
    infoText: String,
    canEdit: String,
    editFieldName: String,
  },
  computed: {
    newFieldValue() {
      if (this.infoFix) {
        if (this.canEdit === "profile") {
          if (this.infoFix[this.editFieldName] && this.type === "date") {
            return moment(this.infoFix[this.editFieldName]).format(
              "YYYY-MM-DD"
            );
          } else return this.infoFix[this.editFieldName];
        }
        if (this.canEdit === "passport") {
          if (
            this.infoFixPassport[this.editFieldName] &&
            this.type === "date"
          ) {
            return this.infoFixPassport[this.editFieldName];
          } else return this.infoFixPassport[this.editFieldName];
        }
      }
      return "";
    },
    isAlertShown() {
      return Boolean(
        !!this.canEdit && !!this.newFieldValue && !this.isNewValueSimilar
      );
    },
    isNewValueSimilar() {
      if (this.newFieldValue) {
        return this.newFieldValue === this.value;
      }
      return true;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    className() {
      return {
        "input--error": this.error,
        "input--disabled": this.disabled,
        "input--icon": this.icon,
        // "input--small": this.small,
        "input--editable": this.isAlertShown,
        "tooltip-right": this.tooltipRight,
      };
    },
    ...mapGetters({
      infoFix: "user/infoFix",
      infoFixPassport: "user/infoFixPassport",
    }),
  },
  methods: {
    autofocus() {
      this.$refs["input"].focus();
    },
    agree() {
      this.value = this.newFieldValue;
      this.$emit("updated");
    },
  },
};
</script>

<style lang="scss">
.input {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  margin-bottom: 11px;
  appearance: none;
  outline: none;
  width: 100%;
  position: relative;
  &__field {
    width: 100%;
  }
  &__fix {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    margin-bottom: 15px;
    p {
      font-weight: 500;
      span {
        color: orange;
      }
    }

    .btn {
      max-width: 90px;
    }
  }
  &__label {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    pointer-events: none;
    &-info {
      vertical-align: middle;
      cursor: pointer;
      pointer-events: visible;
      position: relative;
    }
  }
  .input__item {
    transition: all 0.3s ease;
    margin-top: 6px;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1.7rem;
    position: relative;
    .input__icon {
      color: var(--color--disabled);
    }
    input {
      width: 100%;
      height: 40px;
      outline: none;
      font-size: 1.6rem;
      line-height: 1.9rem;
      padding: 0 8px;
      border: 1px solid var(--input--border);
      border-radius: 8px;
    }
    &:hover,
    &:focus {
      input {
        border-color: var(--background--main);
      }
    }
    &::placeholder {
      color: var(--input--placeholder);
    }
  }
  &--disabled {
    pointer-events: none;
    .input__item {
      pointer-events: none;
      input {
        background: var(--background--disabled);
        color: var(--input--placeholder);
      }
    }
  }
  &--error {
    .input__icon--error {
      position: absolute;
      right: -20px;
      &::after {
        content: "\e901";
        font-family: "icomoon";
        font-size: 1.3rem;
        color: var(--red);
      }
    }
    .input__item {
      input {
        border: 1px solid var(--red);
        &:hover {
          border: 1px solid var(--red);
        }
      }
    }
  }
  &--editable {
    .input__item {
      input {
        border-color: orange;
      }
    }
  }
  @include adaptive(phone-big) {
    &__label {
      &-info {
        [data-tooltip]:hover::after {
          top: 20px;
          left: -20px;
        }
      }
    }
  }
  @include adaptive(phone) {
    &--error {
      .input__icon--error {
        display: none;
      }
    }
  }
}
.tooltip-right {
  .input__label-info {
    @include adaptive(phone-big) {
      [data-tooltip]::after {
        left: unset;
        right: 0;
      }
      [data-tooltip]:hover::after {
        opacity: 1;
        left: unset;
        right: 100%;
      }
    }
  }
}
</style>
